import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { PageLayout, Content } from '../components/PageLayout.tsx';
import LoginForm from '../components/LoginForm.tsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout('Device');
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Content mdxType="Content">
      <p>{`If you're seeing this, it either means you're not connected to
your sanitization device, or that it hasn't been installed correctly.`}</p>
      <p>{`Feel free to `}<a parentName="p" {...{
          "href": "support"
        }}>{`contact us`}</a>{` if you're having trouble.`}</p>
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      